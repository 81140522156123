/*
 * GTM calls preventDefault on link clicks.
 * And does things and then uses window.location.href to change the URL.
 * This is problematic when using Turbolinks (what we use in admin) OR Turbo (for mobile app)
 * Or event Rails UJS or Jquery UJS which rely on intercepting data-remote=true links
 * Reading the obfuscated source code of the offending GTM handler,
 * it looks like there's a guard clause where if the `j` property is set on the event, it's going to ignore it
 * That's what we do here.
 * No idea what `j` means OR how reliable this fix is or if it'll change in the future, but if it works -\_(-_-)_/-
 * Much better than disabling GTM altogether.
 * It also looks like gtm_fixes.js also tried to handle stuff like this, but it only handles some things + seems way more invasive
 */

document.addEventListener('click', (e) => (e.j = true), { capture: true })
document.addEventListener('submit', (e) => (e.j = true), { capture: true })
