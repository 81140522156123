//
// Form submit
//

import $ from 'jquery'
import { defer } from 'lodash-es'
import { handleDataFocus } from '~/common/behaviors/form_focus'
import { debounce } from 'throttle-debounce'

// Submit the form on a change event.
// You can add data-behavior="submit-on-change" anywhere inside the form and it will submit the form anytime any inputs fire a change event.
// If there's a subpart of the form where you don't want change events to trigger this behavior, you can just add the class "prevent-submit-on-change" to the element.
// Any descendants of that element will not trigger this behavior.
// You can also specify:
//  data-remote-submit-url: When set, will submit the form to the specified url using ajax. If not set, we will submit the form normally using $form.submit()
const submitOnChangeHandler = debounce(300, function (e) {
  // We dispatch a change() event in various places in onDomChangedListeners (e.g on inputs with data-checkbox-toggle).
  // Let's not submit the form if isAjaxUpdating is true because otherwise you can easily get into an infinite loop (on a real change event, we submit, the submit rerenders the form that has one checkbox toggle, onDomChanged fires for that checkbox toggle, triggering a change event, which in turn submits the form again and so on).
  if (window.Simplero?.state?.isAjaxUpdating) return

  const $this = $(this)
  const $form = $this.closest('form')
  const prevent =
    $(e.target).closest('.prevent-submit-on-change').closest('form')[0] ===
    $form[0]
  if (!prevent) {
    if ($(this).data('remote-submit-url')) {
      const formData = new FormData($form[0])
      formData.delete('_method')
      $.sajax({
        url: $this.data('remote-submit-url'),
        type: $this.data('ajax-method') || 'POST',
        data: formData,
        processData: false,
        contentType: false,
      })
    } else {
      $form.trigger('submit')
    }
  }
})

$(document).on(
  'change input color-swatch-picker:save',
  '[data-behavior~=submit-on-change]',
  submitOnChangeHandler
)

window.onPageChange('submit on page load', () =>
  $('[data-behavior~=submit-on-page-load]').each(function () {
    setTimeout(() => $(this).submit(), 30)
  })
)

// Save change immediately when changing the state of a switch, like Do Not Contact
$(document).on('change', '[data-behavior~=switch-save]', function (e) {
  const $el = $(e.target)
  const params = {}
  params[$el.attr('name')] = $el.is(':checked')
  $.ajax({
    url: $el.data('url'),
    data: params,
    type: 'PATCH',
    error(xhr, error, message) {
      alert(`There was a problem saving your change: ${message}. Sorry :(`)
    },
    success(data) {
      if ($el.data('behavior').match(/ajax-update/)) {
        window.AjaxHelper.doUpdate(data)
      }
    },
  })
})

$(document).on('click', '[data-behavior~=save]', function (e) {
  // TODO: is this used?? notify-usage candidate
  e.preventDefault()
  $('.page-body-inner form').submit()
})

//#
// Image and link popup
//

$(document).on('click', '[data-behavior=image-popup]', function (e) {
  e.stopPropagation()
  e.stopImmediatePropagation()
  e.preventDefault()

  const a = $(e.target).closest('a')

  const src = a.attr('href')
  let width = a.data('width')
  let height = a.data('height')
  const max_width = $(window).width() * 0.8
  const max_height = $(window).height() * 0.7

  if (width > max_width) {
    height = (height * max_width) / width
    width = max_width
  }
  if (height > max_height) {
    width = (width * max_height) / height
    height = max_height
  }

  const img = $('<img></img>').attr({
    src,
    width: '100%',
    height: '100%',
  })

  const div = $('<div></div>')
    .addClass('modal fade modal-image')
    .append(
      $('<div></div>')
        .addClass('modal-dialog')
        .css({ width, height })
        .append(img)
    )

  $('#modal-container .modal-image').remove()
  $('#modal-container').append(div)
  div.on('shown.bs.modal', () =>
    $(document).on(
      'keydown.modal-image',
      (e) => e.which === 27 && div.modal('hide')
    )
  )
  div.on('hidden.bs.modal', () => $(document).off('keydown.modal-image'))
  div.modal()
})

$(document).on(
  'click',
  'a.popup, a[rel=popup], a[data-behavior~=popup], .popup a:not([data-behavior~=only-anchor])',
  function () {
    if (!$(this).closest('.jp-audio').length) {
      this.setAttribute('target', '_blank')
    }
  }
)

$(document).on('click', '.popup-external a', function (e) {
  const link = $(e.target).closest('a')
  if (
    this.href.match(/:/) &&
    !this.href.match(`^${window.location.origin}/`) &&
    !link.attr('onclick')
  ) {
    e.preventDefault()
    window.open(this.href)
  }
})

$(document).on('click', 'a[data-behavior~=disable-on-update]', function (e) {
  const elementToDisable = $(e.target).closest('a').data().disableElement
  $(elementToDisable).append('<div class="mask"></div>')
})

$(document).on('click', 'a[data-behavior~=disable-on-click]', (e) => {
  const $target = $(e.target).closest('a')
  const $elementToDisable = $target.data('disable-target-selector')
    ? $($target.data('disable-target-selector'))
    : $target
  $elementToDisable.addClass('disabled').attr('disabled', true)
})

//#
// Space follow and like
// Used anywhere else?
//

$(document).on('click', '[data-behavior=like]', function (event) {
  event.preventDefault()
  const $target = $(event.target)
  $.ajax({
    url: $target.attr('href'),
    success(data) {
      const html = $(data.likes)
      $target.closest('.likes-container').replaceWith(html)
      html.find('span').effect('highlight', {}, 1000)
    },
    error() {
      alert(
        "Sorry couldn't save your like, please come back and try again soon."
      )
    },
  })
})

$(document).on(
  'ajax:success',
  '[data-behavior=follow]',
  function (event, data) {
    const $target = $(event.target)
    const html = $(data.follows)
    $target.closest('.follows-container').replaceWith(html)
    html.find('span').effect('highlight', {}, 1000)
  }
)

$(document).on('ajax:error', '[data-behavior=follow]', () =>
  alert(
    "Sorry couldn't save your request, please come back and try again soon."
  )
)

$(document).on('click', '[data-behavior~=link]', function (e) {
  // TODO: is this used?? notify-usage candidate
  e.preventDefault()
  window.Turbolinks.visit(
    $(e.target).closest('[data-behavior~=link]').data('to')
  )
})

// Table row select checkboxes

$(document).on(
  'click',
  'input:checkbox[data-behavior~=select-all]',
  function () {
    $(this)
      .closest('table')
      .find('input:checkbox[data-behavior~=select-row]')
      .prop('checked', this.checked)
  }
)

$(document).on(
  'click',
  'input:checkbox[data-behavior~=select-row]',
  function () {
    const all_checked =
      $(this)
        .closest('table')
        .find('input:checkbox[data-behavior~=select-row]:not(:checked)')
        .length === 0
    $(this)
      .closest('table')
      .find('input:checkbox[data-behavior~=select-all]')
      .prop('checked', all_checked)
  }
)

//#
// Date picker
//

$(document).on('click', '[data-behavior~=datepicker]', (event) =>
  event.preventDefault()
)

window.onDomChanged('[data-behavior~=datepicker]', function () {
  if (!this.datetimepicker) return
  this.datetimepicker({
    format: 'YYYY-MM-DD',
    pickTime: false,
    showToday: true,
    icons: {
      time: 'fa fa-clock-o',
      date: 'fa fa-calendar',
      up: 'fa fa-caret-up',
      down: 'fa fa-caret-down',
    },
  })
})

window.onDomChanged('[data-behavior~=datetimepicker]', function () {
  if (!this.datetimepicker) return
  const time_format_24h = this.parents('body').data('time-format-24h')
  let format = 'YYYY-MM-DD hh:mm a'
  if (time_format_24h) {
    format = 'YYYY-MM-DD HH:mm'
  }
  this.datetimepicker({
    format,
    layout: this.data('datepicker-layout') || 'vertical',
    timeZone: $('body').data('time-zone'),
    showToday: true,
    containerSelector: this.data('datepicker-container-selector') || 'body',
    noLessZeroTop: !!this.data('datepicker-container-selector'),
    icons: {
      time: 'fa fa-clock-o',
      date: 'fa fa-calendar',
      up: 'fa fa-caret-up',
      down: 'fa fa-caret-down',
    },
  })
})

const datepicker_save = function (e) {
  let left, left1
  const $target = $(e.target)
  const data = {}
  data[(left = $target.data('param')) != null ? left : 'date'] = e.date
    ? e.date.format('YYYY-MM-DD hh:mm a')
    : ''
  $.ajax({
    url: $target.attr('href'),
    type: (left1 = $target.data('method')) != null ? left1 : 'POST',
    data,
    success(data) {
      $target.data('DateTimePicker').hide()
      window.AjaxHelper.doUpdate(data)
    },
    error(xhr, text, error) {
      $target.data('DateTimePicker').hide()
      window.AjaxHelper.error(error)
    },
  })
}

$(document).on('dp.save', '[data-behavior~=datepicker-save]', datepicker_save)

//#
// Time picker
//

$(document).on(
  'click',
  '.input-group-addon:has([data-behavior~=timepicker_icon])',
  function () {
    $(this).closest('.controls').find('input.time-mm-hh').focus()
  }
)

//#
// Forms
//

$(document).on('click', 'a[data-personalization-tag]', function (event) {
  let ace
  event.preventDefault()
  const $this = $(this)
  const target = $($this.data('personalization-target'))
  const tag = $this.data('personalization-tag')
  if ((ace = target.data('ace'))) {
    ace.editor.ace.insert(tag)
    ace.editor.ace.focus()
  } else {
    target.replaceSelection(tag)
    target.trigger('change')
    target.focus()
  }
})

$(document).on(
  'shown.bs.modal',
  '.modal[data-behavior~=modal-with-form]',
  function (event) {
    // Make sure it's the modal, and not a tab inside the modal, that's shown.
    if ($(event.target).hasClass('modal')) {
      const form_with_focus = $(event.target).parents('form[data-focus]')
      if (form_with_focus.length > 0) {
        handleDataFocus(form_with_focus)
      } else {
        $(this)
          .find(':input:visible:enabled:not(.close):not(.no-autofocus):first')
          .focus()
      }
    }
  }
)

//#
// Revealing and hiding elements
//

// link: data-behavior="revealer" data-target="selector"
// rel=revealer-container will be hidden when clicked
$(document).on('click', '[data-behavior~=revealer]', function (e) {
  e.preventDefault()
  const $this = $(this)
  const first_input = $($this.attr('data-target'))
    .show()
    .find('input:visible,textarea:visible')
    .first()
  $this.closest('[rel=revealer-container]').hide()
  defer(() => first_input.focus())
})

$(document).on('click', '[data-behavior~=revealer-inverse]', function (e) {
  e.preventDefault()
  const $target = $(this).closest($(this).attr('data-target'))
  $target.hide()
  $target.parent().find('[rel=revealer-container]').show()
})

$(document).on('click', '[data-behavior=hide]', function (e) {
  e.preventDefault()
  $($(this).attr('data-target')).hide()
})

// Hidden products/lists/etc.
$(document).on('click', '[data-behavior~=show-hidden-objects]', function (e) {
  e.preventDefault()
  $($(e.target).attr('data-target') + '.start-hidden').toggle()
})

// Toggle folders in theme file editor
//
// data-target = selector for the thing to toggle
// data-focus  = selector for element to focus after showing
// sets class 'showing' on the link with data-behavior=toggle when the target is showing
//
$(document).on('click', '[data-behavior~=toggle]', function (e) {
  e.preventDefault()
  const target = $($(e.currentTarget).attr('data-target'))
  if (target.hasClass('start-hidden')) {
    target.removeClass('start-hidden')
  } else {
    target.toggle()
  }
  if (target.is(':visible')) {
    const focus = $(e.target).attr('data-focus')
    if (focus != null) {
      $(focus).focus()
    }
  }
  $(e.target).closest('[data-behavior~=toggle]').toggleClass('showing')
})

// Next row has details
$(document).on('click', 'a[data-behavior=show-details-next-row]', function (e) {
  e.preventDefault()
  $(this).closest('tr').next().toggle()
  $(this).children('.toggle-link').toggle()
})

$(document).on('keyup', '[data-behavior=reveal-on-change]', function (e) {
  const elm = $(e.target)
  $(elm.data('target')).toggle(elm.val() !== elm.data('original-value'))
})

//#
// Tooltips & Clickover
//

function showTooltip(
  $tooltip,
  $configEl,
  { title, trigger, placement, delay }
) {
  if (!$configEl) $configEl = $tooltip
  if (!title) title = $configEl.attr('title') || $configEl.data('title')

  if ($tooltip.tooltip && !$configEl.data('modern-tooltip')) {
    $tooltip.tooltip?.({
      title,
      trigger,
      placement,
      delay,
      container: 'body',
    })
  } else {
    if ($tooltip.attr('data-controller')?.includes('popover')) return

    $tooltip.attr('data-content', title)
    $tooltip.attr('data-trigger', trigger)
    $tooltip.attr('data-placement', placement)
    $tooltip.attr('data-theme', 'dark-small')
    $tooltip.attr(
      'data-interactive',
      $configEl.attr('data-interactive') || 'false'
    )
    $tooltip.attr('data-animation', $configEl.attr('data-animation') || 'false')
    $tooltip.attr('data-show-delay', $configEl.attr('data-show-delay') || 0)
    $tooltip.attr('data-hide-delay', $configEl.attr('data-hide-delay') || 0)
    $tooltip.attr('data-touch', $configEl.attr('data-touch') || 'false')
    $tooltip.attr(
      'data-controller',
      `${$tooltip.attr('data-controller')} popover`
    )

    if ($configEl.attr('title')) {
      $configEl.attr('data-title', $configEl.attr('title'))
      $configEl.attr('title', '')
    }
  }
}

function removeTooltip($tooltip) {
  if ($tooltip.tooltip) {
    //TODO: remove bootstrap tooltip
  } else {
    if (!$tooltip.attr('data-controller')?.includes('popover')) return
    const newControllers = $tooltip
      .attr('data-controller')
      .split(' ')
      .filter((controller) => controller != 'popover')
      .join(' ')
    $tooltip.attr('data-controller', newControllers)
  }
}

// Remove any existing tooltips
window.onDomChanged('.tooltip', function () {
  this.remove()
})

window.onDomChanged(
  '[data-behavior~=tooltip],[rel~=tooltip],.s-tooltip',
  function () {
    showTooltip(this, this, {
      trigger: this.data('trigger') || 'hover',
      placement: this.data('placement') || 'top',
      delay: this.data('delay') || 0,
    })
  }
)

/*
 * Add .s-truncate to any element. It'll apply some styles to auto-truncate the text with css.
 * And we'll show a tooltip when it's truncated.
 * You can also have a .s-truncate-attach-tooltip-target class somewhere in the parent and we'll add the tooltip there instead of on this element.
 * You can also specify .s-truncate-show-tooltip-if-hidden to make the condition to show tooltip include the case where the element is completely hidden
 */
window.onDomChanged('.s-truncate', function () {
  let shouldShow = false
  const $tooltip = this.parents('.s-truncate-attach-tooltip-target')
    .addBack()
    .first()
  const showTooltipIfCompletelyHidden = this.hasClass(
    's-truncate-show-tooltip-if-hidden'
  )

  this.parents('.truncate')
    .addBack()
    .each(function (_, el) {
      shouldShow =
        (showTooltipIfCompletelyHidden && el.clientWidth == 0) ||
        el.scrollWidth > el.clientWidth
      if (shouldShow) return false
    })

  if (shouldShow) {
    const title = this.attr('title') || this.text()
    const trigger = this.data('trigger')
    const placement = this.data('placement') || 'top'
    showTooltip($tooltip, this, { title, trigger, placement })
  } else {
    removeTooltip($tooltip)
  }
})

window.onDomChanged('[rel~=popover],[data-behavior~=popover]', function () {
  this.popover({ html: true, trigger: 'hover', container: 'body' })
})

window.onDomChanged('[data-toggle~=popover]', function () {
  this.popover({ html: true, container: 'body' })
})

window.onDomChanged('[rel~=clickover],[data-behavior~=clickover]', function () {
  this.clickover({
    html: true,
    container: 'body',
    width: this.data('clickover-width'),
    sanitize: this.data('sanitize') !== false,
  })
})

$(document).on('click', '[data-toggle=clickover]', function (e) {
  let content
  e.preventDefault()
  const $target = $(e.target).closest('a')
  if ($target.data('content-from') != null) {
    content = $('#' + $target.data('content-from')).html()
  } else if ($target.data('templ') != null) {
    content = window.tmpl($target.data('templ'))
  }

  $target
    .clickover({
      content,
      html: true,
      container: 'body',
      sanitize: $target.data('sanitize') !== false,
    })
    .click((event) => event.preventDefault())
  $target.click()
})

window.onDomChanged('.modal', function () {
  this.attr('tabindex', -1)
})

$(document).on('click', '[data-behavior=generate-coupon-code]', function (e) {
  e.preventDefault()
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVXYZ0123456789'
  let code = ''
  for (let num = 1; num <= 10; num++) {
    const idx = Math.round(Math.random() * (chars.length - 1))
    code += chars[idx]
  }
  $(this).closest('.form-group').find('input').val(code)
})

$(document).on(
  'click touchstart',
  '[data-behavior=expand_on_click]',
  function (e) {
    let editor
    e.preventDefault()
    const target = $(e.target)
    const elm = target
      .closest('.expanded_content')
      .hide()
      .siblings('.collapsed_content')
      .show()
      .find('[autofocus]')
      .first()
    if ((editor = elm.data('editor'))) {
      editor.focus()
    } else {
      elm.focus()
    }
  }
)

$(document).on('click touchstart', '[data-behavior~=read-more]', function (e) {
  e.preventDefault()
  const $target = $(e.target)
  $target.closest('.read-more-expand').css('max-height', 'none')
  return $target.closest('.read-more').remove()
})

window.onDomChanged('[data-format=bigtext]', function () {
  this.bigtext()
})

$(document).on('click', '[data-behavior=show-super-admin]', function (e) {
  e.preventDefault()
  $('.super-admin').show()
})

$(document).on('click', '[data-behavior=hide-super-admin]', function (e) {
  e.preventDefault()
  $('.super-admin').hide()
})

$(document).on(
  'click touchstart',
  '[data-behavior~=dropdown-with-partial]',
  function (e) {
    const menu = $(e.target).closest('.dropdown').find('.dropdown-menu')
    defer(() => menu.find('input:visible').first().focus())
  }
)

// data-prompt="Label"
//
// Submits to value of href attribute
//
// Additional data attributes:
//   maxlen:      maximum length. Default no maxlen.
//   value:       Initial value. Default blank.
//   ajax-method: HTTP Method. Default POST.
//   attr:        Name of attribute holding the value. Default 'value'.
//   match:       Regexp the value needs to match
//   match-msg:   Error message if it doesn't match.
//
$(document).on('click', '[data-prompt]', function (e) {
  e.preventDefault()
  const $target = $(e.target).closest('[data-prompt]')
  const maxlen = $target.data('maxlen')
  const attr = $target.data('attr') || 'value'
  const match = $target.data('match')
  const match_msg = $target.data('match-msg') || 'Not in the right format'
  const shouldDisable =
    $.rails.disableFormElement && !!$target.data('disable-with')
  let value = $target.data('value') || ''
  while ((value = prompt($target.data('prompt'), value))) {
    if (value === '') {
      break
    }

    if (maxlen && value.length > maxlen) {
      alert(`Too long. Maximum length is ${maxlen}`)
    } else if (match && !value.match(new RegExp(match))) {
      alert(match_msg)
    } else {
      $target.data('value', value)
      shouldDisable && $.rails.disableFormElement($target)
      // Can't use data('url') or data('method') because Rails overrides that and submits the click before we get to it
      $.sajax({
        url: $target.data('ajax-url') || $target.attr('href'),
        type: $target.data('ajax-method') || 'POST',
        data: { [attr]: value },
        complete: function () {
          shouldDisable && $.rails.enableFormElement($target)
        },
      })
      break
    }
  }

  // Enable target if it has the behavior "disable-on-click" if the value was not entered (in which case no request would've been made)
  if (!value) {
    const targetDisabledOnUpdate = $target
      .data('behavior')
      ?.indexOf('disable-on-click')
    if (targetDisabledOnUpdate && targetDisabledOnUpdate !== -1) {
      $target.removeClass('disabled').attr('disabled', false)
    }
  }
})

// data: { behavior: 'sortable', reorder_url: reorder_admin_whatever_path, items: 'div', handle: '.sortable-handle' }
//
window.onDomChanged('[data-behavior~=sortable]', function () {
  const $this = this
  // The scrollSensitivity is needed for the sticky header, so it starts scrolling up when the user is near the header
  const opts = {
    scrollSensitivity: 150,
  }

  opts.items = $this.data('items') || 'div'

  if (this.data('handle') !== null) {
    opts.handle = this.data('handle') || '.sortable-handle'
  }

  if (this.data('sortable-placeholder') !== null) {
    opts.placeholder = this.data('sortable-placeholder')
    opts.forcePlaceholderSize = true
  }

  opts.update = function () {
    // Automatically update 'position' hidden input, for models using acts_as_list
    const positions = $this.find('[data-behavior=sortable-position]')
    if (positions[0]) {
      let index = 0
      positions.each((_, pos) => {
        index += 1
        $(pos).val(index)
      })
    }

    // Submit to controller when relevant
    const url = $this.data('reorder-url')
    if (url) {
      $this.sortable('disable')
      $.ajax({
        url,
        data: $this.sortable('serialize'),
        success(data) {
          $this.sortable('destroy')
          window.AjaxHelper.doUpdate(data)
        },
        error() {
          window.AjaxHelper.error(`Error saving your sort order`)
          $this.sortable('enable')
        },
      })
    }
  }

  this.sortable(opts)
})

$(document).on('click', '[data-toggle=collapse]', (e) => e.preventDefault())

$(document).on(
  'click',
  '[data-behavior=asset-grab-current-frame]',
  function (e) {
    e.preventDefault()
    const target = $(e.target).closest('a')
    const video = $('.' + target.data('video-class'))
      .first()
      .find('video')
    const seconds =
      video[0]?.currentTime || video.data('videojs-player').currentTime()
    const type = target.data('type')
    if (seconds != null) {
      $.sajax({
        url: target.attr('href'),
        data: {
          seconds,
          type,
        },
      })
    } else {
      alert('Please start the video and pause at the frame you want.')
    }
  }
)

$(document).on(
  'click touchstart',
  '[data-behavior=hide-admin-only]',
  function (e) {
    e.preventDefault()
    return $('.admin-only').toggle()
  }
)

window.onDomChanged('[data-behavior=observe]', function () {
  const $this = this
  const interval = $this.data('interval') ? $this.data('interval') * 1000 : 1000
  const event = $this.data('event') ? $this.data('event') : 'keyup'
  const on_elm = $this.data('on') ? $($this.data('on')) : $this

  const callback = () =>
    $.sajax({
      url: $this.data('ping-url'),
    })

  const observer = new window.Observer(callback, { interval })

  on_elm.on(`${event}.observer`, () => observer.modified())
})

window.onDomChanged('.modal.start-in', function () {
  $(this).modal('show')
})

$(document).on('click', 'a[data-do-update]', function (e) {
  e.preventDefault()
  const elm = $(e.target).closest('a')
  const update = elm.data('do-update')
  window.AjaxHelper.doUpdate(update)
})

// If you mark table cells with class 'drag-static', we will swap the contents of those cells, so they effectively remain in place
// Useful for things like position number, or schedule dates in the case of newsletters

const setupTableDnD = function (elm) {
  // Store all static cells in an array
  const static_content = {}
  $('tr', elm).each(function (i, tr) {
    if (!$(tr).hasClass('nodrop') && !$(tr).parent().is('thead')) {
      static_content[i] = {}
      $('td.drag-static, td.drag-static-inside', tr).each(function (j, td) {
        let $elem = $(td)
        // Note: Even if a .drag-static-inside column doesn't have .drag-static element inside it we still need to set an undefined value.
        if ($elem.hasClass('drag-static-inside')) {
          $elem = $elem.find('.drag-static')
        }
        static_content[i][j] = $elem.html()
      })
    }
  })

  $(elm).tableDnD({
    dragHandle: '.drag-handle',
    onDragClass: 'tr-dragging',
    onDrop() {
      return $.sajax({
        url: elm.data('href'),
        data: elm.tableDnDSerialize(),
      })
    },
    onMove() {
      $(elm).find('.tr-dragging').finish()
      $('tr', elm).each((i, tr) =>
        $('td.drag-static, td.drag-static-inside', tr).each(function (j, td) {
          if (static_content[i] && static_content[i][j]) {
            let $elem = $(td)
            if ($elem.hasClass('drag-static-inside')) {
              $elem = $elem.find('.drag-static')
            }
            if ($elem) {
              $elem.html(static_content[i][j])
            }
            $elem.changed()
          }
        })
      )
      return true
    },
  })
}

window.onDomChanged('[data-behavior~=table-dnd]', function () {
  setupTableDnD(this)
})

//#
// Autoload tab from remote
//

$(document).on('click', 'a[data-load-tab]', function (e) {
  const tab = $(e.target)
  const target = $(tab.attr('href'))

  if (target.find('.loading_indicator')[0] != null) {
    return $.ajax({
      url: tab.data('load-tab'),
      type: 'GET',
      success(data) {
        return target.html(data.content)
      },
      error: window.AjaxHelper.errorFunc,
    })
  }
})

//#
// Disable clicking on a link with disabled="disabled" attribute.
// We use this when activating. It works fine in Safari, but Chrome seems to let the click go through.
//

$(document).on('click', 'a[disabled=disabled]', (e) => e.preventDefault())

window.onDomChanged(
  "[data-behavior~=track-dirty-text-inputs] input[type='text'], [data-behavior~=track-dirty-text-inputs] textarea",
  function () {
    const $this = $(this)
    $this.data('initial-input-value', $this.val())
    $this.data('dirty-tracking', true)
  }
)

const hasDirtyInputs = function (container) {
  let dirty = false
  container.find('input[type="text"], textarea').each(function () {
    if (
      !dirty &&
      $(this).data('dirty-tracking') &&
      $(this).data('initial-input-value') !== $(this).val()
    ) {
      dirty = true
    }
  })
  return dirty
}

//#
// Makes the modal close click trigger an additional confirmation if the modal has dirty text inputs
$(document).on('hide-with-trigger-event.bs.modal', function (e) {
  const isUserEvent = e.triggerEvent?.originalEvent?.isTrusted
  const modal = $(e.target).closest('.modal')
  if (modal && hasDirtyInputs(modal) && isUserEvent) {
    if (
      !confirm(
        'You have unsaved changes. Are you sure you want to close this window?'
      )
    ) {
      e.preventDefault()
    }
  }
})
